<template>
  <div>
    <template v-if="isList">
      <!-- <a-upload
        :file-list="fileList"
        :list-type="listType"
        :accept="accept"
        :customRequest="handleUpload"
        :transformFile="transformFile"
        :remove="fileRemove"
        @change="handleChange"
        @preview="handlePreview"
      >
        <div v-show="fileList && fileList.length < limt">
          <a-button v-if="listType == 'picture'">
            <a-icon type="upload" />选择文件
          </a-button>
          <a-icon v-else type="plus" />
        </div>
      </a-upload>-->
      <!-- 预览 -->
      <!-- <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>-->

      <!-- 
          :on-remove="handleRemove" 
          action="https://jsonplaceholder.typicode.com/posts/"
          :before-remove="beforeRemove"
          :on-preview="handlePictureCardPreview"
      -->
      <el-upload
        class="upload-uploader"
        :list-type="listType"
        action
        :limit=this.limit
        :on-exceed="handleExceed"
        :http-request="handleUpload"
        :file-list="fileList"
        :on-preview="handlePictureCardPreview"
        :on-change="handleChange"
        :on-remove="handleRemove"
      >
        <!-- <el-button size="small" type="primary">点击上传</el-button> -->
        <div v-if="listType == 'picture'">
          <el-button size="small" type="primary">点击上传</el-button>
        </div>
        <div class="else">
          <img v-if="imgUrl" :src="imgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </template>
    <template v-else>
      <!-- <a-upload list-type="picture-card" :show-upload-list="false" :accept="accept" :customRequest="handleUpload" :transformFile="transformFile" @change="handleChange">
        <img v-if="imgUrl" :src="imgUrl" alt="avatar" class="avatar" />
        <a-icon v-else :type="loading ? 'loading' : 'plus'" />
      </a-upload>-->
      <div v-if="fileType == 'headPic'">
        <el-upload
          class="avatar-uploader"
          action
          :http-request="handleUpload"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :show-file-list="false"
        >
          <div>
            <div class="headerimg">
              <img :src="imgUrl" alt class="img" />
              <div>
                <p>修改头像</p>
              </div>
            </div>
          </div>
        </el-upload>
      </div>
      <div class="no-list" v-else>
        <el-upload
          class="avatar-uploader"
          action
          :http-request="handleUpload"
          :on-change="handleChange"
          :on-remove="handleRemove"
          :show-file-list="false"
        >
          <img v-if="imgUrl" :src="imgUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { getSign } from '../api/myself';
import { uploadImage } from '../api/upload';
let fileDir = "web/test/";
let imgBaseUrl = "https://saenjiaoyu.oss-cn-beijing.aliyuncs.com/";
let fileHeaders = {
  headers: { "Content-Type": "multipart/form-data", Accept: "*" },
};
/* 使用实例
<fileUpload isList @fileCallback="fileCallback" :options="{
    listType:'picture', //上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
    fileType:"image", //"video"、"music"、"image" 默认图片
    multiple:false, // 多选
    limt:9, //限制数量
    list:[], //列表回显 ['http://a.png','http://b.png',]
    imgUrl:'', // 单图片回显 'http://a.png'
  }"></fileUpload>
*/
export default {
  props: {
    options: {
      type: Object,
      default: null
    },
    isList: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 99,
    },
  },
  data() {
    const oFileType = {
      all: '*',
      image: '.jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP',
      video: '.3gp,.rmvb,.flv,.wmv,.avi,.mkv,.mp4,.wav',
      music: '.mp3, .wav, .wmv',
      other: '*',
    }
    const defaultOptions = {
      listType: 'picture-card', //上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
      fileType: "image", //"video"、"music"、"image"、"all"、"other" 默认图片 headPic 头像
      multiple: false, // 多选
      limt: 9, //限制数量
      list: [], //列表回显 ['http://a.png','http://b.png',]
      imgUrl: '', // 'http://a.png'
    }
    const options = this.options
    const fileList = [];
    if (options) {
      Object.keys(options).forEach((key) => {
        defaultOptions[key] = options[key];
      });
      if (defaultOptions.fileType != 'image') {
        defaultOptions.listType = 'picture'
      }
      options.list && options.list.forEach((item, key) => {
        fileList.push({
          uid: 'old-' + key,
          name: item.split(fileDir)[1],
          fileName: fileDir + item.split(fileDir)[1],
          status: 'done',
          url: item,
        })
      })
      defaultOptions.fileList = fileList
    }
    const { listType, fileType, multiple, limt, imgUrl } = defaultOptions;
    return {
      accept: oFileType[fileType],
      listType,
      fileType,
      multiple,
      limt,
      imgUrl,
      fileList,
      loading: false,
      fileName: "", //删除时使用

      previewVisible: false,
      previewImage: "",
      dialogVisible: false,
      dialogImageUrl: '',
      lists:[],
    };
  },
  watch: {
    options: {
      handler(newVal) {
        const { fileList, imgUrl, isResetImg } = newVal
        if (fileList) this.fileList = fileList
        if (imgUrl || isResetImg) this.imgUrl = imgUrl
      },
      deep: true
    },
  },
  created() {
  },
  methods: {
    //上传
    handlePreview(file) {
      if (this.fileType == 'image') {
        this.previewImage = file.url;
        this.previewVisible = true;
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`最多上传三张图片`);
    },
    handleChange(file, fileList) {
      // if (file.status !== "loading") {
      // console.log(file, fileList, "999");
      // }
      this.fileList = fileList;
      console.log('handleChangefileList',fileList);
      let backUrl = imgBaseUrl + '/' + file.uid + '.png';
      // let backUrl = file.url;
      // if (this.isList) {
      backUrl = fileList.map(({ name, uid }) => {
        return { name, url: imgBaseUrl + '/' + uid + '.png' }
      })
      // }
      this.$emit("fileCallback", backUrl);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.handleChange(file, fileList)
    },
    // beforeUpload(file) {
    //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //   if (!isJpgOrPng) {
    //     this.$message.error('You can only upload JPG file!');
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message.error('Image must smaller than 2MB!');
    //   }
    //   return isJpgOrPng && isLt2M;
    // },
    //文件上传
    handleUpload({ file }) {
      const that = this;
      that.loading = true;
      let formData = new FormData();
      console.log("file====",file);
      formData.append('files', file);
      axios.post('/api/pc/alioss/uploadImage', formData).then((res) => {
        console.log("REs",res);
        this.lists.push({
          url: res.data.data[0]
        });
        this.fileList = this.lists;
        //that.imgUrl = res.data.data[0];
        this.$emit("fileCallback", this.fileList);
        that.loading = false;
      });
      // uploadImage(formData).then((res) => {

      //   this.lists.push({
      //     url: res.data.data[0]
      //   });
      //   this.fileList = this.lists;

      // });
      // let baseUrl = this.baseUrl;
      // console.log(this.baseUrl);
      // getSign().then(({ data }) => {
      //   console.log(data);
      //   const formData = new FormData();
      //   // fileList.forEach(file => {
      //   // fileDir = data.dir
      //   console.log(file);
      //   // const fileName = file.fileName;
      //   const fileName = file.uid + ".png";
      //   formData.append("name", file.name);
      //   formData.append("key", fileName);
      //   formData.append("policy", data.policy);
      //   formData.append("signature", data.signature);
      //   formData.append("OSSAccessKeyId", data.accessid);
      //   formData.append("success_action_status", 200);
      //   formData.append("file", file);
      //   // });
      //   that.loading = true;

      //   var uploadUrl = data.host;

      //   if (baseUrl) {
      //     uploadUrl = 'http://localhost:8080/upFile'
      //   }else {
      //     uploadUrl += '/upFile';
      //   }

      //   axios.post(uploadUrl, formData, fileHeaders).then((res) => {
      //     if (that.isList) {
      //       that.fileList.forEach((item) => {
      //         console.log(item);
      //         if (item.fileName == fileName) {
      //           // item.status = "done";
      //         }
      //       });
      //     } else {
      //       if (that.fileName) {
      //         that.apiRemove(that.fileName, uploadUrl);
      //       }
      //       that.fileName = fileName;
      //       // that.imgUrl = file.url;
      //       that.imgUrl = `${data.host}/${that.fileName}`;
      //       console.log(this.imgUrl);
      //       that.loading = false;
      //     }
      //   });
      // })
      // axios
      //   .get(that.baseUrl + "/api/pc/alioss/getSign", { params: {} })
      //   .then(({ data }) => {

      //   });
    },
    handleAvatarSuccess(res, file) {
      console.log({ res, file });
    },
    //删除文件
    fileRemove(file) {
      this.apiRemove(file.fileName);
    },
    apiRemove(fileName, uploadUrl) {
      let params = new URLSearchParams();
      params.append("fileName", fileName);
      // axios.post(uploadUrl + "/admin/alioss/del", params).then((res) => {
      //   // console.log(res);
      // });
    },
    // 复写文件  重新定义了文件的名字
    transformFile(file) {
      let fileExtension = file.name.split(".").pop();
      let fileUid = file.uid;
      if (!fileUid) {
        fileUid = new Date().getTime();
      }
      const newFileName = fileDir + fileUid + "." + fileExtension;
      file.url = imgBaseUrl + newFileName;
      file.fileName = newFileName;
      return file;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  width: 100px;
  height: 100px;
}
.no-list {
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}

.headerimg {
  text-align: center;
  position: relative;

  .img {
    width: 112px;
    height: 112px;
    border-radius: 50%;
  }
  div {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-top: -116px;
    position: relative;
    p {
      background: rgba(1, 1, 1, 0.3);
      font-size: 14px;
      font-weight: 500;
      color: #f8f9ff;
      width: 112px;
      height: 30px;
      line-height: 30px;
      margin-top: 82px;
    }
  }
}
</style>
