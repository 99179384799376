<template>
    <div class="ask">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="ask_header">
            <img src="@/assets/ask/ask_header_banner.png" alt class="ask_header_banner" />
            <div class="ask_header_box">
                <div class="ask_header_box_left">
                    <img src="@/assets/ask/ask_logo.png" alt />
                    <div class="ask_header_box_left_view">
                        <p>{{userInfo.nickName}} 您可以免费提问哦～</p>
                        <p>
                            <el-button type="primary" @click="goService()">咨询课程顾问</el-button>
                            <el-button type="warning" @click="becomeVIP()">成为VIP ></el-button>
                        </p>
                    </div>
                </div>
                <div class="ask_header_box_right">
                    <input type="text" placeholder="请输入内容" v-model="searchInput"  @keyup.enter.prevent="searchEnterFun($event)" />
                    <img src="@/assets/ask/search.png" alt @click="goSearchpage()"/>
                </div>
            </div>
        </div>
        <div class="ask_main">
            <div class="ask_main_left">
                <div class="ask_main_left_operate">
                    <div class="ask_main_left_operate_header">
                        <p>
                            <img src="@/assets/ask/question_icon.png" alt />
                            <span>您的问题</span>
                            <span>会员可免费享受答疑服务</span>
                        </p>
                        <p @click="dialogVisible = true">点击查看问答细则</p>
                    </div>
                    <div class="ask_main_left_operate_question">
                        <el-input v-model="question" placeholder="一句话准确描述您的疑问！" maxlength="100"></el-input>
                    </div>
                    <div class="ask_main_left_operate_questionDescribe">
                        <img src="@/assets/ask/question_icon.png" alt />
                        <span>问题描述</span>
                        <span>（选填）</span>
                    </div>
                    <div class="ask_main_left_operate_textarea">
                        <el-input
                            type="textarea"
                            :rows="8"
                            placeholder="因老师有时间限制，请“一题一问”，以便老师能尽快做出解答，谢谢配合！"
                            v-model="questionExplain"
                            maxlength="1000"
                            show-word-limit
                        ></el-input>
                    </div>
                    <div class="ask_main_left_operate_classify">
                        <p>问题分类：</p>
                        <p>
                            <el-radio v-model="radio" v-for="(item,index) in wdTypeList" :key="index" :label="item.id">{{ item.name }}</el-radio>
                        </p>
                    </div>
                    <div class="ask_main_left_operate_upLoad">
                        <div class="ask_main_left_operate_upLoad_header">
                            <!-- <p>
                                <el-upload
                                    class="upload-demo"
                                    action="https://jsonplaceholder.typicode.com/posts/"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :file-list="fileList"
                                    list-type="picture"
                                >
                                    <img src="@/assets/ask/pic_icon.png" alt />
                                    <el-button size="small" type="primary">点击上传</el-button>
                                </el-upload>
                               
                            </p>-->
                            <div class="ask_main_left_operate_upLoad_header_left">
                                <p>
                                    <img src="@/assets/ask/pic_icon.png" alt />
                                    <span>上传图片</span>
                                    <span>最多上传3张图片</span>
                                </p>
                                <fileUpload isList :options="{}" :limit=3 @fileCallback="fileBack"></fileUpload>
                            </div>
                            <p class="ask_main_left_operate_upLoad_header_right">
                                <el-button type="primary" @click="submit_question()">提交问题</el-button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="ask_main_left_list">
                    <div class="ask_main_left_list_tab">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="推荐">
                                <el-radio-group v-model="radio1" @change="onClassify">
                                    <el-radio-button
                                        v-for="(item,index) in wdTypeList"
                                        :key="index"
                                        :label="item.id"
                                    >{{ item.name }}</el-radio-button>
                                </el-radio-group>
                                <div
                                    class="ask_main_left_list_item"
                                    v-for="(askItem,key) in recommendlist"
                                    :key="key"
                                    @click="toAskDetail(askItem.wdId)"
                                >
                                    <div class="ask_main_left_list_item_box">
                                        <div class="ask_main_left_list_item_box_left">
                                            <img :src="askItem.customerHeadPic" alt />
                                            <!-- <p>{{ askItem.answerUserNickName }}</p> -->
                                        </div>
                                        <div class="ask_main_left_list_item_box_right">
                                            <!-- <p>{{ askItem.questionName }}<br><span>{{ askItem.answerUserNickName }}</span></p> -->
                                            <p>{{ askItem.questionName }}</p>
                                            <p
                                                v-if="askItem.answerUserId != '' && askItem.answerUserId != null && askItem.answerUserId != undefined"
                                            >
                                                <img :src="askItem.answerUserHeadPic" alt />
                                                <span>{{ askItem.answerUserNickName }}</span>
                                                <span>解答</span>
                                                <span>{{ askItem.answerTime }}</span>
                                                <span></span>
                                                <span>{{ askItem.readCount }}阅读</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="最新">
                                <el-radio-group v-model="radio1" v-if="false">
                                    <el-radio-button v-for="(item,index) in wdTypeList" :key="index" :label="item.id">{{ item.name }}</el-radio-button>
                                </el-radio-group>
                                <div
                                    class="ask_main_left_list_item"
                                    v-for="(askItem,key) in newestlist"
                                    :key="key"
                                    @click="toAskDetail(askItem.wdId)"
                                >
                                    <div class="ask_main_left_list_item_box">
                                        <div class="ask_main_left_list_item_box_left">
                                            <img :src="askItem.customerHeadPic" alt />
                                            <!-- <p>{{ askItem.questionName }}</p> -->
                                        </div>
                                        <div class="ask_main_left_list_item_box_right">
                                            <p>{{ askItem.questionName }}</p>
                                            <p
                                                v-if="askItem.answerUserId != '' && askItem.answerUserId != null && askItem.answerUserId != undefined"
                                            >
                                                <img :src="askItem.answerUserHeadPic" alt />
                                                <span>{{ askItem.answerUserNickName }}</span>
                                                <span>解答</span>
                                                <span>{{ askItem.answerTime }}</span>
                                                <span></span>
                                                <span>{{ askItem.readCount }}阅读</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination 
                        layout="prev, pager, next" 
                        @current-change="handleCurrentChange" 
                        prev-text="上一页" 
                        next-text="下一页" 
                        :page-size="10"
                        :total="questionCount"></el-pagination>
                </div>
            </div>
            <div class="ask_main_right">
                <div class="ask_main_right_top">
                    <div class="ask_main_right_top_icon">
                        <p>
                            <img src="@/assets/ask/information_icon.png" alt />
                            <span>免费提问</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/consult.png" alt />
                            <span>专家坐镇</span>
                        </p>
                        <p>
                            <img src="@/assets/ask/alarm.png" alt />
                            <span>极速解答</span>
                        </p>
                    </div>
                    <div class="ask_main_right_top_QRcode">
                        <img :src="website.qrCodeRight" alt />
                    </div>
                    <div class="ask_main_right_top_text">下载APP，财税问题随时问</div>
                </div>
                <div class="ask_main_right_bottom">
                    <div class="ask_main_right_bottom_header">
                        <img src="@/assets/ask/gambit.png" alt />
                        <span>热门问答</span>
                    </div>
                    <div class="ask_main_right_bottom_list">
                        <div class="ask_main_right_bottom_list_item" v-for="(item,index) in hotList" :key="index" @click="toAskDetail(item.wdId)" style="cursor:pointer;">
                            <p style="cursor:pointer;">
                                <span>问</span>
                                <span class="longtext_one">{{ item.questionName }}</span>
                            </p>
                            <p style="cursor:pointer;" v-if="item.answerContent!='' && item.answerContent!=null && item.answerContent!=undefined">
                                <span>答</span>
                                <span class="longtext_one">{{ item.answerContent }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog
            title="问答细则"
            :visible.sync="dialogVisible"
            width="42%"
            :show-close="false"
            :close-on-click-modal="false"
        >
            <div class="rule">
                <div class="conter_text" style="text-align:left;" v-html="text"></div>
                <div class="rule_btn" >
                    <el-button style="margin-bottom:20px;" type="primary" @click="dialogVisible = false">我知道啦</el-button>
                </div>
            </div>
        </el-dialog>
        <t_footer></t_footer>
        <!-- <popup_login :todos="todos" :isType="isType"  @setTodos="getTodos"></popup_login> -->
    </div>
</template>
<style >
    .el-radio-button__inner{
        border-left: 1px solid #ddd;
    }
    .el-upload-list--picture-card .el-upload-list__item{
        width: 80px;
        height: 80px;
    }
  .upload-uploader{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  .el-upload--picture-card{
    width: 80px !important;
    height: 80px !important;
    line-height: 80px;
    margin: 0 8px 8px 0;
  }
  .longtext_one {
        width: 100%; 
        overflow:hidden; 
        white-space: nowrap;
        text-overflow:ellipsis;
        text-align:left;
    }
</style>>

<script>
import t_header from '@/components/t_header/index.vue';
import t_footer from '@/components/t_footer/index.vue';
// import popup_login from '@/components/popup_login/index.vue';
import fileUpload from '../../components/FileUpload.vue';
import { wd_typelist, wd_recommendlist, closely_question, publish_question, wd_latest, wd_expertlist, wd_replylist, hotWd_list } from "@/api/other";
import { wangzhanpeizhi } from "@/api/login";
import { agreement } from "@/api/other";
export default {
    name: 'ask',
    components: {
        t_header,
        t_footer,
        fileUpload
        // popup_login
    },
    data() {
        return {
            radio: '',
            question: '',
            textarea: '',
            radio1: 0,
            isSort: false,
            // todos:false,
            isType: false,
            search_input: '',
            activeName: 0,
            wdTypeList: [],
            recommendlist: [],
            dialogVisible: false,
            fileList: [],
            searchInput: '',
            questionExplain: '',
            newestlist: [],
            wdTypeId: '',
            hotList: [],
            questionCount:0,
            hotCount:0,
            isUserInfo:false,
            page:0,
            userInfo:{},
            website:{},
            text:''
        }
    },
    mounted() {
        var that=this
        that.wd_typelist()
        var user = localStorage.getItem('setuserInfo');
        if(user != null && user != 'null'){
            this.userInfo = JSON.parse(user)
        }
        this.wangzhanpeizhi();
        var obj={typeName:'问答会员协议'}
        this.agreement(obj);
    },
    methods: {
        checkLogin(){
          const userInfo = localStorage.getItem("setuserInfo");
          if(userInfo==null || userInfo=='null'){
            this.isUserInfo=true;
            setTimeout(() => {
              this.isUserInfo=false;
            }, 0);
            return false
          }else{
            return userInfo
          }
        },
        agreement(obj){
            agreement(obj).then((res)=>{
                this.text = res.data
            })
        },
        searchEnterFun(e){
            var keyCode = window.event ? e.keyCode : e.which;
            // console.log("回车搜索", keyCode, e);
            if (keyCode == 13) {
                this.goSearchpage()
            }
        },
        wangzhanpeizhi(){
            wangzhanpeizhi().then(res=>{
                // console.log("网站配置");
                if(res.data.code == 0){
                    this.website = res.data.data;
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        goSearchpage(){
            let routeUrl =this.$router.resolve({
                path:'/searchpage',
                query:{
                    searchText:this.searchInput,
                    num:2
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        // 父传子
        getTodos(e){
            this.isUserInfo=e
        },
        handleRemove() { },
        handlePreview() {

        },
        // 去成为vip
        becomeVIP() {
            const userInfo = this.checkLogin();
            if(!userInfo){
              return false
            }
            let routeUrl =this.$router.resolve({
                path: '/vipPayPage',
                query: {}
            });
            window.open(routeUrl.href, '_blank');
        },
        // 分页展示
        handleCurrentChange(val){
            // console.log(`当前页: ${val}`);
            this.page=val-1
            this.getList()
        },
        //   去客服
        goService(){
            var url='https://tb.53kf.com/code/client/28a6552fc10b901b687a66116675d2e07/1'
            // let routeData = this.$router.resolve({ path: url, query: {} }); 
            window.open(url, '_blank');
        },
        // 提交问题
        submit_question() {
            const userInfo = this.checkLogin();
            if(!userInfo){
              return false
            }
            let fileList = this.fileList;
            let str = '';
            if(fileList.length >0){
                fileList.forEach((item, index) => {
                    str += item.url + ',';
                });
            }
            var obj = {};
            if(str != ''){
                str = str.substring(0, str.lastIndexOf(','));
                obj = {
                    questionName: this.question,
                    typeId: this.radio,
                    questionDescribe: this.questionExplain,
                    questionPics: str
                }
            }else{
                obj = {
                    questionName: this.question,
                    typeId: this.radio,
                    questionDescribe: this.questionExplain,
                }
            }
            publish_question(obj).then(res => {
                if (res.data.code == 0) {
                    this.$message.success("已成功发布");
                    this.wd_recommendlist();
                    //location.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 去问答详情
        toAskDetail(id) {
            let routeUrl =this.$router.resolve({
                path: '/askDetail',
                query: {
                    wdId:id
                }
            });
            window.open(routeUrl.href, '_blank');
        },
        
        // 切换问答列表
        onClassify() {
            this.wdTypeId = this.radio1;
            this.page=0
            this.getList();
        },
        // tab切换
        handleClick(tab, event) {
            this.radio1 = 0;
            this.wdTypeId = '';
            this.page=0
            this.activeName = tab.index;
            this.getList()
        },
        // 
        getList() {
            let active = this.activeName;
            if (active == '1') {
                this.wd_latest()
            } else {
                this.wd_recommendlist()
            }
        },
        // 问题分类
        wd_typelist() {
            wd_typelist().then(res => {
                // console.log(res)
                if (res.data.code == 0) {
                    this.wdTypeList = res.data.data
                    this.radio = res.data.data[0].id
                    this.radio1 = res.data.data[0].id
                    this.wd_recommendlist()
                    this.hotWd_list();
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 问答：推荐列表
        wd_recommendlist() {
            let obj = {
                page:this.page,
                wdTypeId:this.radio1
            };
            // this.wdTypeId && (obj.wdTypeId = this.wdTypeId)
            wd_recommendlist(obj).then(res => {
                // console.log(res)
                if (res.data.code == 0) {
                    this.recommendlist = res.data.data
                    this.questionCount=res.data.count
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 问答：最新列表
        wd_latest() {
            var obj={
                page:this.page,
                wdTypeId:this.radio1
            }
            wd_latest(obj).then(res => {
                // console.log(res)
                if (res.data.code == 0) {
                    this.newestlist = res.data.data
                    this.questionCount=res.data.count
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 热门问答列表
        hotWd_list() {
            var obj={
                pageSize:5,
                type:1,
                page:0,
                typeId:this.radio1
            }
            hotWd_list(obj).then(res => {
                if(res.data.code==0){
                    this.hotList=res.data.data
                }else{
                    this.$measage.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },

        fileBack(fileList) {
            this.fileList = fileList;
        }
    }
}
</script>


<style>
</style>